<template>
  <v-row align="center" justify="center" class="">
    <v-col cols="12">
      <div class="fn24-weight7 text-center" style="color: #1a347f">
        Online Consulting
      </div>
    </v-col>
    <v-card
      width="100%"
      height="110"
      class="mx-auto"
      style="
        background: linear-gradient(268.1deg, #d5e4ff 7.54%, #c1d1ff 100.72%);
      "
    >
      <v-row align="center" justify="center">
        <v-col cols="4" class="mt-4">
          <!-- <img src="@/assets/profile/no1.png" class="my-7 mx-6" /> -->
          <v-avatar size="76"><v-img :src="image_profile" /></v-avatar>
        </v-col>
        <v-col cols="7" class="mt-4">
          <div class="fn16-weight7 mb-2" style="color: #1a347f">
            {{ first_name }} {{ last_name }}
          </div>
          <div class="fn14">{{ avaliableTime }}</div>
        </v-col>
      </v-row>
    </v-card>
    <v-col cols="12" class="pa-0">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-stepper v-model="stepper" elevation="0">
          <v-stepper-items>
            <v-stepper-content step="1" class="px-0 mb-2">
              <div class="fn16-weight7 text-center mb-2" style="color: #1a347f">
                Select a Date
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row align="center" justify="center">
                <v-col cols="12" md="8">
                  <v-date-picker
                    v-model="date"
                    width="360"
                    class="mb-4"
                    color="#07204B"
                    full-width
                    :min="firstDate"
                  ></v-date-picker>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  @click="checkDate(date)"
                >
                  Next
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2" class="px-0 mb-10">
              <div class="fn16-weight7 text-center mb-2" style="color: #1a347f">
                Select Time
              </div>
              <v-divider class="my-4"></v-divider>
              <div class="text-center" style="color: #1a347f">
                <span class="mr-14 fn16-weight7">{{ date }}</span>
                <span class="ml-16 fn14" @click="stepper -= 1"
                  ><a-icon type="left" /> Change Date</span
                >
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row>
                <v-col
                  cols="6"
                  md="3"
                  v-for="(choice, index) in dateChoices"
                  :key="index"
                >
                  <!-- createdAt: "2022-06-30T10:15:49.406Z"
day: "SUN"
doctor: {id: 19, first_name: 'donut', last_name: 'doctor', is_mon: true, is_tue: false, …}
doctorId: 19
id: 232
is_avalible: false
time: "17:00 - 18:00"
updatedAt: "2022-06-30T10:16:30.178Z"
[[Prototype]]: Object -->
                  <v-btn
                    :ref="'b' + index"
                    elevation="0"
                    class="
                      rounded-pill
                      fn20
                      text-center
                      my-4
                      mx-1
                      d-inline-block
                    "
                    style="width: 175px; height: 44px; background: #ededed"
                    @click="selectDate(choice, index)"
                    :disabled="choice.is_avalible ? false : true"
                  >
                    <div class="my-1">{{ choice.time }}</div>
                  </v-btn>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  @click="next()"
                >
                  Next
                </v-btn>
              </div>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  elevation="0"
                  outlined
                  class="mt-2 rounded-lg text-capitalize"
                  @click="stepper -= 1"
                >
                  <!-- class="rounded-lg text-capitalize buttoncolor" -->
                  back
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" class="px-0 mb-5">
              <div class="ma-4" style="color: #1a347f">
                <span class="mr-14 fn16-weight7">{{ date }}, {{ time }}</span>
              </div>
              <v-divider class="my-4"></v-divider>
              <v-row class="ma-4">
                <!-- <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">Your Topic*:</div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="fn16" style="color: #1a347f">
                    <v-autocomplete
                      dense
                      outlined
                      hide-details
                      :items="['Symptoms consulting']"
                    />
                  </div>
                </v-col> -->
                <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">Your Title*:</div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="fn16" style="color: #1a347f">
                    <v-text-field
                      v-model="title"
                      dense
                      outlined
                      hide-details
                      placeholder="type your title here"
                      :rules="rules.required"
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">Description:</div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="fn16" style="color: #1a347f">
                    <v-textarea
                      v-model="description"
                      dense
                      outlined
                      hide-details
                      rows="3"
                      placeholder="type your title here"
                      :rules="rules.required"
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="fn16" style="color: #1a347f">
                    Attach images or videos:
                  </div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <div class="mb-2">
                    <img src="@/assets/icon/upload.png" />
                    <span class="ml-2 fn16-weight7" style="color: #1a347f"
                      >Upload files</span
                    >
                  </div>
                  <!-- <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="showImage"
                    dragText="ลากไฟล์มาที่นี่"
                    browseText="เลือกไฟล์"
                    primaryText="รูปภาพเริ่มต้น"
                  ></vue-upload-multiple-image> -->
                  <v-col style="border: solid, 0.5px;border-color: lightgrey;text-align: -webkit-center;" cols="3">
                    <v-img
                      :src="showImage ? showImage : defaultImage"
                      max-width="50px"
                      max-height="50px"
                      @click="changePic1()"
                    >
                      <input
                        type="file"
                        ref="image"
                        id="picTure1"
                        accept="image/*"
                        @change="showPicture1($event)"
                        style="display: none"
                        hidden
                    /></v-img>
                  </v-col>
                </v-col>
              </v-row>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  @click="submit()"
                >
                  Confirm my appointment
                </v-btn>
              </div>
              <div class="text-center">
                <v-btn
                  width="360"
                  height="57"
                  elevation="0"
                  outlined
                  class="mt-2 rounded-lg text-capitalize"
                  @click="stepper -= 1"
                >
                  <!-- class="rounded-lg text-capitalize buttoncolor" -->
                  back
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-dialog v-model="opendialog" :overlay-opacity="0.9" max-width="380">
          <!-- <a-icon
            type="close"
            class="white--text text-right"
            @click="opendialog = false"
          /> -->
          <v-card
            class="pt-0 pb-2"
            max-width="375"
            min-height="378"
            max-height="390"
          >
            <v-row align="center" justify="center">
              <span
                class="fn24-weight7 text-center pt-8"
                style="color: #1a347f"
              >
                Confirm Your Appointment
              </span>

              <v-card-text>
                <v-row align="center" justify="center" no-gutters>
                  <v-col cols="12" class="mt-4">
                    <!-- <img src="@/assets/profile/no1.png" class="my-7 mx-6" /> -->
                    <v-row justify="center">
                      <v-avatar size="76"
                        ><v-img :src="image_profile"
                      /></v-avatar>
                    </v-row>

                    <v-row align="center" justify="center">
                      <span class="fn16-weight7 mb-2" style="color: #1a347f">
                        {{ first_name }} {{ last_name }}
                      </span>
                    </v-row>
                    <!-- <span class="fn14">{{ avaliableTime }}</span> -->
                    <v-divider class="my-4"></v-divider>
                  </v-col>

                  <v-col cols="12">
                    <v-row no-gutters class="pa-0" justify="center">
                      <v-col cols="6" class="px-0 text-center">
                        <span class="fn16-weight7">{{ date }}</span>
                      </v-col>
                      <v-col cols="6" class="px-0 text-center">
                        <span class="fn16-weight7">{{ time }}</span>
                      </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  width="300"
                  height="57"
                  dark
                  elevation="0"
                  class="rounded-lg text-capitalize buttoncolor"
                  block
                  @click="
                    () => {
                      (opendialog = false), (dialogView = true), confirm();
                    }
                  "
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-card>
        </v-dialog>
      </v-form>

      <v-dialog
        v-model="dialogView"
        persistent
        :overlay-opacity="0.9"
        max-width="380"
      >
        <!-- <a-icon type="close" class="text-right white--text" @click="cancel" /> -->
        <v-card
          class="pt-8 pb-2"
          max-width="375"
          min-height="451"
          max-height="451"
          ><v-row align="center" justify="center">
            <span class="fn24-weight7 text-center" style="color: #1a347f">
              Your Appointment Has Been Confirmed!
            </span>
            <span>
              <v-row align="center" justify="center">
                <v-col cols="12" class="mt-4">
                  <v-row justify="center"
                    ><v-avatar size="76"
                      ><v-img :src="image_profile" /></v-avatar
                  ></v-row>
                </v-col>
                <v-col cols="12" class="mt-4">
                  <v-row justify="center">
                    <span class="fn16-weight7 mb-2" style="color: #1a347f">
                      {{ first_name }} {{ last_name }}
                    </span></v-row
                  >
                </v-col>
              </v-row>
              <v-divider class="my-4"></v-divider>
              <v-row no-gutters>
                <v-col cols="6" class="text-center">
                  <span class="fn16-weight7">{{ date }}</span>
                </v-col>
                <v-col cols="6" class="text-center">
                  <span class="fn16-weight7">{{ time }}</span>
                </v-col>
              </v-row>
              <v-divider class="my-4"></v-divider>
              <v-row justify="center" align="center">
                <v-col cols="10" class="ma-0 text-center">
                  <span class="fn10-weight7">
                    You can preview your appointment in ‘My Appointments’ and
                    click the Zoom link before 15 mins of event time.
                  </span>
                </v-col>
              </v-row>
            </span>
            <v-card-actions>
              <v-btn
                width="300"
                height="57"
                dark
                elevation="0"
                class="rounded-lg text-capitalize buttoncolor"
                block
                @click="goToAppointments()"
              >
                View My Appointments
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialognotavai">
        <v-card
          class="pt-0 pb-2"
          max-width="375"
          min-height="378"
          max-height="390"
        >
          <v-row align="center" justify="center">
            <span class="fn24-weight7 text-center pt-8" style="color: #1a347f">
              Date isn't Available
            </span>

            <v-card-text>
              <v-row align="center" justify="center" no-gutters>
                <v-col cols="12" class="mt-4">
                  <!-- <img src="@/assets/profile/no1.png" class="my-7 mx-6" /> -->
                  <v-row justify="center">
                    <v-avatar size="76"
                      ><v-img :src="image_profile"
                    /></v-avatar>
                  </v-row>

                  <!-- <v-row align="center" justify="center">
                    <span class="fn16-weight7 mb-2" style="color: #1a347f">
                      วันนี้ปิดทำการ กรุณาเลือกวันใหม่
                    </span>
                  </v-row> -->
                  <!-- <span class="fn14">{{ avaliableTime }}</span> -->
                  <v-divider class="my-4"></v-divider>
                </v-col>

                <v-col cols="12">
                  <v-row align="center" justify="center">
                    <span class="fn16-weight7 my-2" style="color: red">
                      วันนี้ปิดทำการ กรุณาเลือกวันใหม่
                    </span>
                  </v-row>
                  <!-- <v-row no-gutters class="pa-0" justify="center">
                    <v-col cols="6" class="px-0 text-center">
                      <span class="fn16-weight7">{{ date }}</span>
                    </v-col>
                    <v-col cols="6" class="px-0 text-center">
                      <span class="fn16-weight7">{{ time }}</span>
                    </v-col>
                  </v-row> -->
                  <v-divider class="my-4"></v-divider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn
                width="300"
                height="57"
                dark
                elevation="0"
                class="rounded-lg text-capitalize buttoncolor"
                block
                @click="dialognotavai = false"
              >
                Confirm
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-card></v-dialog
      >
    </v-col>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  components: {
    VueUploadMultipleImage,
  },
  data() {
    return {
      dialognotavai: false,

      stepper: 1,
      clicked: false,
      time: "",
      selectedTime: "",
      date: new Date().toISOString().substr(0, 10),
      firstDate: new Date().toISOString().slice(0, 10),
      dateChoices: [
        // { time: "09:00 - 10:00", value: "09:00:00" },
        // { time: "10:00 - 11:00", value: "10:00:00" },
        // { time: "12:00 - 13:00", value: "12:00:00" },
        // { time: "13:00 - 14:00", value: "13:00:00" },
        // { time: "14:00 - 15:00", value: "14:00:00" },
        // { time: "15:00 - 16:00", value: "15:00:00" },
        // { time: "16:00 - 17:00", value: "16:00:00" },
        // { time: "17:00 - 18:00", value: "17:00:00" },
      ],
      file_upload: "",
      picture_1: "",
      pic1URL: "",
      defaultImage: require("@/assets/upload.png"),
      showImage: "",
      imageName1: "",
      opendialog: false,
      dialogView: false,

      doctorId: 0,
      typeName: "",
      typeDescription: "",
      activeFlag: true,
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      first_name: "",
      last_name: "",
      image_profile: "",
      avaliableTime: "",
      title: "",
      description: "",
      doctorData: [],
      selectedDay: "",
      selectedDayValue: "",
      arrayEvents: null,
    };
  },
  async created() {
    this.doctorId = parseInt(this.$route.query.id);
    // await this.getOneDoctor();
    // await this.getUser();
    await this.getProfile();
    // this.arrayEvents = [...Array(6)].map(() => {
    //   const day = Math.floor(Math.random() * 30);
    //   const d = new Date();
    //   d.setDate(day);
    //   return d.toISOString().substr(0, 10);
    // });
  },
  watch: {
    date: {
      handler(date) {
        var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
        // var d = new Date(dateString);
        // var dayName = days[d.getDay()];
        var some = new Date(date);

        console.log("date", days[some.getDay()]);
      },
    },
  },
  methods: {
    async checkDate(date) {
      var days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
      // var d = new Date(dateString);
      // var dayName = days[d.getDay()];
      var some = new Date(date);
      // this.selectedDayValue = date;
      this.selectedDay = days[some.getDay()];
      console.log("date", days[some.getDay()]);

      if (this.selectedDay == "SUN") {
        if (this.doctorData.is_sun == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      if (this.selectedDay == "MON") {
        if (this.doctorData.is_mon == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      if (this.selectedDay == "TUE") {
        if (this.doctorData.is_tue == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      if (this.selectedDay == "WED") {
        if (this.doctorData.is_wed == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      if (this.selectedDay == "THU") {
        if (this.doctorData.is_thu == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      if (this.selectedDay == "FRI") {
        if (this.doctorData.is_fri == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      if (this.selectedDay == "SAT") {
        if (this.doctorData.is_sat == true) {
          this.stepper = 2;
        } else {
          this.dialognotavai = true;
        }
      }
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/timeslots?doctorId=${this.doctorData.id}&day=${this.selectedDay}`
      );
      console.log("dateAvailable", response.data.data);
      this.dateChoices = response.data.data;

      // is_fri: false
      // is_mon: true
      // is_sat: true
      // is_sun: true
      // is_thu: false
      // is_tue: false
      // is_wed: false
      // สำเร็จ
      // this.stepper = 2
    },
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
    },
    async getUser() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
      this.tel = this.userData.tel ? this.userData.tel : "";
      this.postcode = this.userData.postcode ? this.userData.postcode : "";
      await this.getOneDoctor();
    },
    async getOneDoctor() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/doctors/` + this.doctorId
        // `http://localhost:8081/doctors/` + this.doctorId
      );
      console.log("doctor data", response.data.data);
      this.doctorData = response.data.data;
      this.first_name = response.data.data.first_name;
      this.last_name = response.data.data.last_name;
      this.image_profile = response.data.data.image_profile;
    },
    selectDate(value, index) {
      if (this.selectedTime) {
        this.$refs[this.selectedTime][0].$el.classList.remove("btn-active");
      }
      const name = "b" + index;
      this.selectedTime = name;

      this.clicked = !this.clicked;
      this.time = value.time;
      console.log(this.$refs[name][0]);
      this.$refs[name][0].$el.classList.add("btn-active");
      console.log("this.time", this.time);
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log("data", formData, index, fileList);
      // this.showImage = fileList;
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.file_upload = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.file_upload);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    goToAppointments() {
      this.$router.push("/ManageAppointments");
    },
    cancel() {
      this.dialogView = false;
      this.$router.push("ManageConsulting");
    },
    async next() {
      console.log("this.stepper", this.stepper);
      // is_fri: false
      // is_mon: true
      // is_sat: true
      // is_sun: true
      // is_thu: false
      // is_tue: false
      // is_wed: false

      if (this.stepper === 2) {
        if (this.selectedTime) {
          this.stepper += 1;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `กรุณาเลือกเวลา`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    submit() {
      if (this.stepper === 3) {
        if (this.$refs.createForm.validate(true)) {
          this.opendialog = true;
        }
      }
    },
    async confirm() {
      const data = {
        title: this.title,
        description: this.description,
        first_name: this.first_name,
        last_name: this.last_name,
        day: this.selectedDay,
        date: this.date,
        time: this.time,
        file_upload: this.file_upload,
        userId: this.userData.id,
        doctorId: this.doctorId,
      };

      // if (this.$refs.createForm.validate(true)) {
      //   var user = JSON.parse(
      //     Decode.decode(localStorage.getItem("userBiData"))
      //   );
      // const auth = {
      //   headers: {
      //     Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
      //   },
      // };
      //   const data = {
      //     id: this.doctorId,
      //     type: this.typeName,
      //     description: this.typeDescription,
      //     status: this.typeStatus,
      //   };
      const response = await this.axios.post(
        // `http://localhost:8081/appoinments`,
        `${process.env.VUE_APP_API}/appoinments/`,
        data
        // auth
      );
      console.log("submit", response);
      //   console.log(response.data.response_status);
      //   if (response.data.response_status == "SUCCESS") {
      //     this.$swal.fire({
      //       icon: "success",
      //       text: `สร้างประเภทสินค้าสำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.$router.push("ManageType");
      //   } else {
      //     this.$swal.fire({
      //       icon: "error",
      //       text: `สร้างประเภทสินค้าไม่สำเร็จ`,
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //   }
      // }
    },
  },
};
</script>

<style scoped>
.buttoncolor {
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}

.btn-active {
  width: 175px;
  height: 44px;
  background: linear-gradient(
    268.1deg,
    #d5e4ff 7.54%,
    #c1d1ff 100.72%
  ) !important;
  border: 3px solid #1a347f;
}
</style>
